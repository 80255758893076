import React from 'react';
import { withPrefix } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Translate } from 'src/components/Translate';
import './TermsConditionsGetStarted.module.scss';

const metadata = {
  title: 'Welcome! | HDC for State Farm',
};

type TermsConditionsGetStartedProps = {
  successCallback: () => void;
};

const TermsConditionsGetStarted = ({ successCallback }: TermsConditionsGetStartedProps): JSX.Element => {
  return (
    <>
      <Helmet title={metadata.title} />
      <div data-cy="terms-conditions-get-started" className="terms-conditions-get-started">
        <div className="left-side">
          <h1 className="header">
            <Translate resourceKey="termsAndConditions.getStarted.HDC" />
          </h1>
          <p className="lead">
            <Translate resourceKey="termsAndConditions.steps" />
          </p>
          <ol className="icon-list icon-list_numbers">
            <li>
              <Translate resourceKey="termsAndConditions.signTermsAndConditions" />
            </li>
            <li>
              <Translate resourceKey="termsAndConditions.submit.W9" />
            </li>
          </ol>
          <div className="divider"></div>
          <button
            className="button button_primary button_primary_medium"
            onClick={successCallback}
            data-cy="terms-conditions-get-started-button"
          >
            <Translate resourceKey="termsAndConditions.getStarted" />
          </button>
        </div>
        <div className="right-side">
          <img className="right-side__image" src={withPrefix('/terms-conditions/blue-corvette.png')} alt="" />
        </div>
      </div>
    </>
  );
};

export default TermsConditionsGetStarted;
