import React, { useEffect, useState } from 'react';
import { ArrowRight16 } from '@hagerty/icons';
import { Button } from '@hagerty/react-components';
import { Helmet } from 'react-helmet';

import styles from './agent-welcome.module.scss';

import { CopyVideoModalColumns } from 'src/components/CopyMediaColumns';
import { List } from 'src/components/List';
import useVideoAssets from 'src/components/Video/useVideoAssets';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';
import { CopySection } from 'src/features/agent-onboarding/types';
import { useAgentOnboardingAssets } from 'src/features/agent-onboarding/use-agent-onboarding-assets';
import 'src/features/agent-onboarding/styles.scss';

const metadata = {
  title: 'Welcome! | HDC for State Farm',
};

type Props = {
  nextFn: () => void;
};

const AgentWelcome: React.FC<Props> = ({ nextFn }) => {
  const [callOnce, setCallOnce] = useState(false);
  const videos = useVideoAssets('agent-onboarding.welcome.videos');
  const { welcomeCopySections } = useAgentOnboardingAssets();
  const section = welcomeCopySections[0];
  const { trackView } = useTrackingContext();

  useTracking('sf_onboard_welcome_view', null, true);

  useEffect(() => {
    if (trackView && !callOnce) {
      trackView('sf_onboard_welcome_view', null, {
        virtual_page_name: 'welcome',
      });
      setCallOnce(true);
    }
  }, [trackView]);

  const getCopyChildren = (section: CopySection) => {
    if (section.list && section.list.length) {
      return (
        <>
          <p className={styles.agentWelcome__listTitle}>{section.listTitle}</p>
          <List items={section.list} />
          <Button
            className={`button button_primary ${styles.agentWelcome__cta}`}
            testId="agent-welcome-get-started-button"
            onClick={nextFn}
          >
            {section.action}
            <ArrowRight16 />
          </Button>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Helmet title={metadata.title} />
      <div className="container">
        <div className={`${styles.agentWelcome} agent-onboarding-container`} data-cy="onboarding-welcome">
          <section
            key={`onboarding-welcome-${section.title}`}
            data-cy={`onboarding-welcome-${section.title}`}
            className={styles.agentWelcome__section}
          >
            <CopyVideoModalColumns
              copyChildren={getCopyChildren(section)}
              overline={section.overline}
              title={section.title}
              paragraph={section.paragraph}
              video={videos[0]}
              thumbnailStyles={styles.agentWelcome__thumbnail}
            />
          </section>
        </div>
      </div>
    </>
  );
};

export { AgentWelcome };
