import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import marked from 'marked';

import makeSvgSingleLine from 'src/components/makeSvgSingleLine';
import svgDataURL from '../../helpers/svgDataURL';
import { getAltText } from 'src/helpers/getAltText';
import { getEmMarkup } from 'src/helpers/parseMarkdown';

import { Assets, AssetTitle, CopySection, VideoSection } from './types';

export const getColorMarkup = (raw: string): string => {
  if (!raw) return;
  const markup = raw.replace('**', '<span>').replace('**', '</span>');
  return markup;
};

const parseParagraph = (tokens: marked.Token[]): string => {
  let paragraph = '';
  tokens.map((item) => {
    switch (item.type) {
      case 'text': {
        paragraph += item.text;
        break;
      }
      case 'strong': {
        paragraph += `<strong>${item.text}</strong>`;
        break;
      }
    }
  });
  return paragraph;
};

export const parseCopySections = (markdown: string): CopySection[] => {
  const tokens = marked.lexer(makeSvgSingleLine(markdown));
  const sections: CopySection[] = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        sections.push({
          title: token.text,
          paragraph: null,
          action: null,
          listTitle: null,
          list: null,
          overline: null,
          image: null,
          svg: null,
        });
        break;
      }
      case 'html': {
        sections[sections.length - 1].svg = svgDataURL(token.text);
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          sections[sections.length - 1].image = {
            alt: getAltText(token.tokens[0].text),
            url: (token.tokens[0] as marked.Tokens.Image).href,
          };
        } else {
          sections[sections.length - 1].paragraph = parseParagraph(token.tokens);
        }
        break;
      }
      case 'list': {
        const section = sections[sections.length - 1];
        section.action = token.items[0]?.text;
        section.listTitle = getColorMarkup((token.items[1]?.tokens[0] as marked.Tokens.Text)?.text);
        const items = (token.items[1]?.tokens[1] as marked.Tokens.List)?.items;
        section.list = items?.map((item) => item.text);
        section.overline = token.items[2]?.text;
        break;
      }
    }
  }

  return sections;
};

export const parseVideoSections = (markdown: string): VideoSection[] => {
  const tokens = marked.lexer(markdown);
  const sections: VideoSection[] = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        sections.push({
          title: getEmMarkup(token.text),
          paragraph: null,
        });
        break;
      }
      case 'paragraph': {
        sections[sections.length - 1].paragraph = parseParagraph(token.tokens);
        break;
      }
    }
  }

  return sections;
};

const useAgentOnboardingAssets = () => {
  const { t, ready } = useTranslation();

  return useMemo<Assets>((): Assets => {
    return {
      welcomeCopySections: parseCopySections(t(AssetTitle.WelcomeCopySections)),
      roadsideCopySections: parseCopySections(t(AssetTitle.RoadsideCopySections)),
      benefitsCopySections: parseCopySections(t(AssetTitle.BenefitsCopySections)),
      benefitsVideoSections: parseVideoSections(t(AssetTitle.BenefitsVideoSections)),
    };
  }, [ready]);
};

export { useAgentOnboardingAssets };
