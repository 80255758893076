import React, { useState } from 'react';

import { AgentRoadside } from './agent-roadside/agent-roadside';
import { AgentWelcome } from './agent-welcome/agent-welcome';
import { AgentBenefits } from './agent-benefits/agent-benefits';

import scrollToTop from 'src/helpers/scrollToTop';
import { useTrackingContext } from 'src/components/TrackingContext';
import logTrackedEvent from 'src/helpers/logTrackedEvent';
import { trackEvent } from 'src/services/tracker';

type AgentOnboardingProps = {
  successCallback: () => void;
};

export const AgentOnboarding: React.FC<AgentOnboardingProps> = ({ successCallback }) => {
  const { trackInteraction } = useTrackingContext();
  const [showWelcome, setShowWelcome] = useState(true);
  const [showRoadside, setShowRoadside] = useState(false);
  const [showBenefits, setShowBenefits] = useState(false);

  const nextToRoadside = () => {
    setShowWelcome(false);
    setShowRoadside(true);
    scrollToTop();
    logTrackedEvent(trackEvent, {
      _tag_name: 'hdc_sf_onboard_start',
      action: 'button click',
      action_name: 'get started',
    });
  };

  const nextToBenefits = () => {
    setShowRoadside(false);
    setShowBenefits(true);
    scrollToTop();
    trackInteraction('button click', 'i understand');
  };

  const backToWelcome = () => {
    setShowRoadside(false);
    setShowWelcome(true);
    scrollToTop();
  };

  const backToRoadside = () => {
    setShowBenefits(false);
    setShowRoadside(true);
    scrollToTop();
  };

  return (
    <>
      {showWelcome && <AgentWelcome nextFn={nextToRoadside} />}
      {showRoadside && <AgentRoadside backFn={backToWelcome} nextFn={nextToBenefits} />}
      {showBenefits && <AgentBenefits backFn={backToRoadside} nextFn={successCallback} />}
    </>
  );
};
