import { navigate, PageProps } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { useTreatments } from '@splitsoftware/splitio-react';

import { useAuthContext } from 'src/components/AuthProvider';

import useCheckTermsConditionsStatus from 'src/api/useCheckTermsConditions';
import { isAgent } from 'src/api/client';

import TermsConditions from 'src/components/organism/TermsConditions/TermsConditions';
import TermsConditionsGetStarted from 'src/components/organism/TermsConditionsGetStarted/TermsConditionsGetStarted';
import { TermsConditionsSigned } from 'src/components/organism/TermsConditionsSigned/TermsConditionsSigned';
import LoadingContainer from 'src/components/LoadingContainer';
import StateFarmPageTemplate from 'src/components/templates/StateFarmPageTemplate/StateFarmPageTemplate';
import { AgentOnboarding } from 'src/features/agent-onboarding';
import { useTrackingContext } from 'src/components/TrackingContext';

import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';
import scrollToTop from 'src/helpers/scrollToTop';

export default function TermsConditionsPage({ location }: PageProps) {
  const { loading: authLoading, brokers } = useAuthContext();
  const { status: termsStatus, loading: termsLoading } = useCheckTermsConditionsStatus();
  const { trackInteraction } = useTrackingContext();
  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeatureAgentOnboarding]);
  const hasAgentOnboarding = treatments[Split.FeatureAgentOnboarding].treatment === Treatment.On;

  const [experiments, setExperiments] = useState([]);
  const [showTerms, setShowTerms] = useState(false);
  const [showTermsAccepted, setTermsAccepted] = useState(false);
  const [delayWorkdayLink, setDelayWorkdayLink] = useState(false);

  const isPageLoading = authLoading || termsLoading || !splitIsReady;
  const isPrincipalAgent = brokers?.isPrincipal;

  const handleSuccessWorkdayDelay = () => {
    setDelayWorkdayLink(true);
    setTermsAccepted(true);
    setTimeout(() => {
      setDelayWorkdayLink(false);
    }, 5000);
  };

  const handleShowTerms = () => {
    if (hasAgentOnboarding) trackInteraction('button click', 'next');
    setShowTerms(true);
    scrollToTop();
  };

  useEffect(() => {
    if (splitIsReady) {
      const variant = hasAgentOnboarding ? Treatment.On : Treatment.Off;
      const testExperience = `${Split.FeatureAgentOnboarding}_${variant}`;
      setExperiments([testExperience]);
    }
  }, [splitIsReady]);

  useEffect(() => {
    if (!isAgent) {
      navigate('/');
    } else if (!isPageLoading && !isPrincipalAgent) {
      navigate('/agent-unauthorized');
    }
  }, [brokers, isPageLoading]);

  useEffect(() => {
    if (!termsLoading && termsStatus?.toLowerCase() === 'valid') {
      navigate(`/agents/commissions/${location.search}`);
    }
  }, [termsLoading, termsStatus]);

  if (isPageLoading || !isPrincipalAgent) {
    return (
      <StateFarmPageTemplate showFooterDisclaimer={false}>
        <LoadingContainer height={500} />
      </StateFarmPageTemplate>
    );
  }

  // If the user has yet to see or accept the T&C
  if (isAgent && !showTerms && !showTermsAccepted && termsStatus?.toLowerCase() !== 'valid') {
    return (
      <StateFarmPageTemplate>
        {hasAgentOnboarding ? (
          <AgentOnboarding successCallback={handleShowTerms} />
        ) : (
          <TermsConditionsGetStarted successCallback={handleShowTerms} />
        )}
      </StateFarmPageTemplate>
    );
  }
  // If the user has seen the T&C but not accepted them
  if (isAgent && showTerms && !showTermsAccepted && termsStatus?.toLowerCase() !== 'valid') {
    return (
      <StateFarmPageTemplate showFooterDisclaimer={false}>
        <TermsConditions successCallback={handleSuccessWorkdayDelay} experiments={experiments} />
      </StateFarmPageTemplate>
    );
  }

  if (isAgent && showTermsAccepted) {
    return (
      <StateFarmPageTemplate>
        <TermsConditionsSigned
          delayWorkdayLink={delayWorkdayLink}
          successCallback={() => {
            navigate(`/agents/commissions/${location.search}`);
          }}
        />
      </StateFarmPageTemplate>
    );
  }

  return null;
}
