// Defined in contentful https://app.contentful.com/spaces/w61n0jg6cdlz/entries/3ld7pEkl402i5AVqm6Mji1 TODO
type Image = {
  alt: string;
  url: string;
};

export type VideoSection = {
  title: string;
  paragraph: string;
};

export type CopySection = {
  title: string;
  paragraph: string;
  action: string;
  listTitle?: string;
  list?: string[];
  overline?: string;
  image?: Image;
  svg?: string;
};

export type Assets = {
  welcomeCopySections: CopySection[];
  roadsideCopySections: CopySection[];
  benefitsCopySections: CopySection[];
  benefitsVideoSections: VideoSection[];
};

export enum AssetTitle {
  WelcomeCopySections = 'agent-onboarding.welcome.sections',
  RoadsideCopySections = 'agent-onboarding.roadside-warning.sections',
  BenefitsCopySections = 'agent-onboarding.benefits.sections',
  BenefitsVideoSections = 'agent-onboarding.benefits.video-sections',
}
