import React, { useEffect, useState } from 'react';
import { ArrowRight16 } from '@hagerty/icons';
import { Button } from '@hagerty/react-components';
import { Helmet } from 'react-helmet';

import styles from './agent-benefits.module.scss';

import { CopyImageColumns } from 'src/components/CopyMediaColumns';
import { CheckList } from 'src/components/CheckList';
import { AgentBenefitsDisclaimer } from 'src/components/Disclaimers';
import useVideoAssets from 'src/components/Video/useVideoAssets';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { Divider } from 'src/components/atom';
import { Translate } from 'src/components/Translate';
import { VideoModal, YoutubePlayer } from 'src/components/Video';
import FadeIn from 'src/components/FadeIn';
import { CopySection } from 'src/features/agent-onboarding/types';
import { useAgentOnboardingAssets } from 'src/features/agent-onboarding/use-agent-onboarding-assets';
import 'src/features/agent-onboarding/styles.scss';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';

const metadata = {
  title: 'Membership Benefits | HDC for State Farm',
};

type Props = {
  nextFn: () => void;
  backFn: () => void;
};

const AgentBenefits: React.FC<Props> = ({ backFn, nextFn }) => {
  const [callOnce, setCallOnce] = useState(false);
  const videos = useVideoAssets('agent-onboarding.benefits.videos');
  const { benefitsCopySections, benefitsVideoSections } = useAgentOnboardingAssets();
  const section = benefitsCopySections[0];
  const { trackView } = useTrackingContext();

  useTracking('sf_onboard_benefits_view', null, true);

  useEffect(() => {
    if (trackView && !callOnce) {
      trackView('sf_onboard_benefits_view', null, { virtual_page_name: 'benefits' });
      setCallOnce(true);
    }
  }, [trackView]);

  const getCopyChildren = (section: CopySection) => {
    if (section.list && section.list.length) {
      return (
        <>
          <p className={styles.agentBenefits__listTitle} dangerouslySetInnerHTML={{ __html: section.listTitle }} />
          <CheckList items={section.list} />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Helmet title={metadata.title} />
      <div className="container">
        <div className={`${styles.agentBenefits} agent-onboarding-container`} data-cy="onboarding-benefits">
          <Breadcrumbs originTransKey={'breadcrumbs.back'} callbackFn={backFn} dataCy="agent-benefits-back" />
          <section key={`onboarding-benefits-${section.title}`} data-cy={`onboarding-benefits-${section.title}`}>
            <div className={styles.agentBenefits__section}>
              <CopyImageColumns
                copyChildren={getCopyChildren(section)}
                title={section.title}
                paragraph={section.paragraph}
                imageAlt={section.image.alt}
                imageUrl={section.image.url}
                svg={section.svg}
              />
            </div>
            <div className={styles.agentBenefits__videoSection}>
              <Translate as="h2" resourceKey="agent-onboarding.benefits.videos.heading" data-cy="videos-heading" />
              <div className="inset-s" />
              <div className={styles.agentBenefits__videoGrid}>
                {benefitsVideoSections.map((v, i) => (
                  <div className={styles.agentBenefits__video} key={i}>
                    <div className={styles.agentBenefits__thumbnail}>
                      <FadeIn duration={0.3} style={{ height: '100%', width: '100%', position: 'absolute' }}>
                        <VideoModal thumbnail={videos[i].imageUrl}>
                          <YoutubePlayer video={videos[i]} />
                        </VideoModal>
                      </FadeIn>
                    </div>
                    <h3 data-cy="video-title" dangerouslySetInnerHTML={{ __html: v.title }} />
                    <p data-cy="video-paragraph">{v.paragraph}</p>
                  </div>
                ))}
              </div>
            </div>
            <Divider margin={20} />
            <Button
              className={`button button_primary ${styles.agentBenefits__cta}`}
              testId="agent-benefits-next-button"
              onClick={nextFn}
            >
              {section.action}
              <ArrowRight16 />
            </Button>
          </section>
        </div>
      </div>

      <footer className="container">
        <AgentBenefitsDisclaimer />
      </footer>
    </>
  );
};

export { AgentBenefits };
