import React from 'react';
import styles from './List.module.scss';

import { ListProps } from './List.types';

export const List: React.FC<ListProps> = ({ className, items }) => {
  return (
    <ol className={`${styles.list} ${className}`}>
      {items.map((item) => (
        <li key={`list-item-${item}`} className={styles.list__item}>
          {item}
        </li>
      ))}
    </ol>
  );
};