import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import marked from 'marked';
import isBrowser from 'src/helpers/isBrowser';

import { YoutubeVideo } from './Video.types';

export const parseVideos = (markdown: string): YoutubeVideo[] => {
  const tokens = marked.lexer(markdown);
  const videos: YoutubeVideo[] = [];

  for (const token of tokens) {
    if (token.type === 'paragraph') {
      switch (token.tokens[0].type) {
        case 'text': {
          // default width, height, and autoplay set per Figma design https://www.figma.com/file/7PbYnz2V9lCaCdubCmdyI1/HDC%3A-Spec?node-id=4230%3A99518
          videos.push({
            videoId: token.tokens[0].text,
            imageUrl: null,
            height: 432,
            width: 768,
            playerVars: {
              autoplay: 0,
              mute: 1,
              enablejsapi: 1,
              origin: isBrowser ? window.location.href : '',
            },
          });
          break;
        }
        case 'image': {
          videos[videos.length - 1].imageUrl = (token.tokens[0] as marked.Tokens.Image).href;
          break;
        }
      }
    }
  }

  return videos;
};

const useVideoAssets = (resource: string) => {
  const { t, ready } = useTranslation();

  return useMemo<YoutubeVideo[]>((): YoutubeVideo[] => {
    return parseVideos(t(resource));
  }, [ready]);
};

export default useVideoAssets;
