import React, { useEffect, useState } from 'react';
import { ArrowRight16 } from '@hagerty/icons';
import { Button } from '@hagerty/react-components';
import { Helmet } from 'react-helmet';

import styles from './agent-roadside.module.scss';

import { useAgentOnboardingAssets } from 'src/features/agent-onboarding/use-agent-onboarding-assets';
import { CopySection } from 'src/features/agent-onboarding/types';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { CopyImageColumns } from 'src/components/CopyMediaColumns';
import 'src/features/agent-onboarding/styles.scss';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';

const metadata = {
  title: 'Roadside Coverage | HDC for State Farm',
};

type Props = {
  backFn: () => void;
  nextFn: () => void;
};

export const AgentRoadside: React.FC<Props> = ({ backFn, nextFn }) => {
  const [callOnce, setCallOnce] = useState(false);
  const { roadsideCopySections } = useAgentOnboardingAssets();
  const section = roadsideCopySections[0];
  const { trackView } = useTrackingContext();

  useTracking('sf_onboard_roadside_warning_view', null, true);

  useEffect(() => {
    if (trackView && !callOnce) {
      trackView('sf_onboard_roadside_warning_view', null, {
        virtual_page_name: 'roadside-warning',
      });
      setCallOnce(true);
    }
  }, [trackView]);

  const getCopyChildren = (section: CopySection) => {
    return (
      <Button
        className={`button button_primary ${styles.agentRoadside__cta}`}
        testId="agent-roadside-i-understand-button"
        onClick={nextFn}
      >
        {section.action}
        <ArrowRight16 />
      </Button>
    );
  };

  return (
    <>
      <Helmet title={metadata.title} />
      <div className="container">
        <div className={`${styles.agentRoadside} agent-onboarding-container`} data-cy="onboarding-roadside-warning">
          <Breadcrumbs originTransKey={'breadcrumbs.back'} callbackFn={backFn} dataCy="agent-roadside-back" />
          <section
            key={`onboarding-roadside-warning-${section.title}`}
            data-cy={`onboarding-roadside-warning-${section.title}`}
            className={styles.agentRoadside__section}
          >
            <CopyImageColumns
              copyChildren={getCopyChildren(section)}
              title={section.title}
              paragraph={section.paragraph}
              imageAlt={section.image.alt}
              imageUrl={section.image.url}
              svg={section.svg}
            />
          </section>
        </div>
      </div>
    </>
  );
};
